import React from "react"

const GoogleMap = () => {
  return (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14883.84821078582!2d72.75466806977535!3d21.153908400000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbfd4fdcc239f4393!2sGhanshyam%20Digital%20LLP!5e0!3m2!1sen!2sin!4v1657795751350!5m2!1sen!2sin"
        width="100%"
        title={"contact us"}
        height="450"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  )
}

export default GoogleMap
